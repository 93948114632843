import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function AiRobot() {
  return (
    <StaticImage
      src="../../images/ai_robot.jpg"
      alt="AI Robot"
      placeholder="blurred"
      layout="constrained"
    />
  );
}

export default AiRobot;
