import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function VibrantDoor() {
  return (
    <StaticImage
      src="../../images/vibrant_door.jpg"
      alt="Office view"
      placeholder="blurred"
      layout="constrained"
      loading="eager"
      imgStyle={{
        objectFit: 'cover',
      }}
    />
  );
}

export default VibrantDoor;
