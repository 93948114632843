import React from 'react';
import AiRobot from '../components/static_images/AiRobot';
import OfficeView from '../components/static_images/OfficeView';
import Security from '../components/static_images/Security';
import VibrantDoor from '../components/static_images/VibrantDoor';
import Layout from '../components/Layout';
import GreenCare from '../components/static_images/GreenCare';
import ContentWrapper from '../components/ContentWrapper';
import ContentBanner from '../components/ContentBanner';
import SEO from '../components/Seo';

const banners = [
  {
    title: "Remote first. Global is Local.",
    subtitle: 'Texas',
    adaption: 'standard-reverse',
    classNames: 'content-banner--md-contrast content-banner--bottom-left-diagonal-gradient',
    child: OfficeView,
    parallaxProps: {
      speed: 0,
    },
  },
  {
    title: 'U | X',
    subtitle: "Interfaces aren't bad jokes.We won't have to explain them.",
    adaption: 'standard',
    classNames: 'content-banner--md-contrast content-banner--top-left-diagonal-gradient',
    child: VibrantDoor,
  },
  {
    title: 'Site Reliability',
    subtitle: 'Scalable. Maintainable. Available.',
    adaption: 'standard-reverse',
    classNames: 'content-banner--md-contrast content-banner--bottom-left-diagonal-gradient',
    child: GreenCare,
  },
  {
    title: 'Security. Beginning to ... infinity.',
    subtitle: "Zero Knowledge Proof. We can prove it",
    adaption: 'standard',
    classNames: 'content-banner--md-contrast content-banner--bottom-right-diagonal-gradient',
    child: Security,
  },
  {
    title: 'Dream big. Real BIG.',
    subtitle: 'Analytics, AI, and Machine Learning',
    adaption: 'feature-text',
    classNames: 'content-banner--hard-light content-banner--top-right-diagonal-gradient',
    child: AiRobot,
  },
];
function IndexPage() {
  return (
    <Layout>
      <SEO
        url="https://www.turinglamarr.com"
        title="Turring Lamarr"
        description='Turing Lamarr is a software development company in Texas. We specialize in web, mobile, and cloud services.'
        image="/static/78f4a3519376fda1775222d13c6edd62/b7ef5/RobotFocusAtNight.webp"
      />
      {
        banners.map(({
          title,
          subtitle,
          adaption,
          delimiter = null,
          classNames = '',
          parallaxProps,
          child,
        }, index) => (
          <ContentWrapper key={subtitle}>
            <ContentBanner
              title={title}
              subtitle={subtitle}
              adaption={adaption}
              delimiter={delimiter}
              classNames={classNames}
              parallaxProps={parallaxProps}
              position={index}
            >
              {child()}
            </ContentBanner>
          </ContentWrapper>
        ))
      }
    </Layout>
  );
}

export default IndexPage;
