import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function OfficeView() {
  return (
    <StaticImage
      src="../../images/office_view.jpg"
      alt="Office view"
      placeholder="blurred"
      layout="constrained"
      loading="eager"
    />
  );
}

export default OfficeView;
