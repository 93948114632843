import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Security() {
  return (
    <StaticImage
      src="../../images/security.jpg"
      alt="Office view"
      placeholder="blurred"
      layout="constrained"
    />
  );
}

export default Security;
